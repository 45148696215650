import { Component, OnInit } from '@angular/core';
import { IngresoTipo } from '../../../model/ingresosTipo.model';
import { GastoIngresoService } from '../../services/gasto-ingreso.service';
import { ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-ingreso-tipo-selec',
  templateUrl: './ingreso-tipo-selec.component.html',
  styleUrls: ['./ingreso-tipo-selec.component.scss'],
})

export class IngresoTipoSelecComponent implements OnInit {

  buscar: string;
  ingresosTipo: IngresoTipo[] = [];
  constructor( private ingresoService: GastoIngresoService,
               private modalCtrl: ModalController,
               private ui: UiSereviceService) { }

  async ngOnInit() {
    const ing = await this.ingresoService.getIngresoTipo();
    if (!ing.ok){
      this.ui.alertError(ing.error);
      return;
    }
    this.ingresosTipo = ing.ingresosTipo;
  }

  seleccionado(ingresoTipo: IngresoTipo){
    this.modalCtrl.dismiss(ingresoTipo);
  }
  cerrar(){
    this.modalCtrl.dismiss();
  }

  buscarIt(event: any){
    this.buscar = event.detail.value;
  }

}
