import { Pipe, PipeTransform } from '@angular/core';
import { IngresoTipo } from '../../model/ingresosTipo.model';

@Pipe({
  name: 'tipoIngreso'
})
export class TipoIngresoPipe implements PipeTransform {

  transform(tipos: IngresoTipo[], text: string): IngresoTipo[] {
    if (!text || text === '' ){
      return tipos;
    }
    text = text.toLowerCase();
    let resp: IngresoTipo[];
    resp = tipos.filter(data => {
      return String(data.cod).toLocaleLowerCase().includes(text) ||
             data.nombre.toLocaleLowerCase().includes(text);
    });
    return resp;
  }

}
