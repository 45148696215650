import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Sociedad, SociedadResp } from '../../model/sociedad.model';

@Injectable({
  providedIn: 'root'
})
export class SociedadesService {

  constructor( private dataService: DataService) { }

  getSociedades() {
    return new Promise<SociedadResp>(resolve => {
      this.dataService.getData('sociedades').then((s: SociedadResp) => {
        resolve(s);
      });
    });
  }


  getSociedadeId(sociedadId) {
    return new Promise<SociedadResp>(resolve => {
      this.dataService.getData(`sociedades/${sociedadId}`).then((s: SociedadResp) => {
        resolve(s);
      });
    });
  }

  setSociedad(sociedad: Sociedad){
    return new Promise<SociedadResp>(resolve => {
      this.dataService.setData('sociedades', sociedad).then((s: SociedadResp) => {
        resolve(s);
      });
    });
  }
  updateSociedad(sociedad: Sociedad){
    return new Promise<SociedadResp>(resolve => {
      this.dataService.updateData(`sociedades/${sociedad._id}`, sociedad).then((s: SociedadResp) => {
        resolve(s);
      });
    });
  }

  delSociedad(sociedad: Sociedad){
    return new Promise<SociedadResp>(resolve => {
      this.dataService.delData(`sociedades/${sociedad._id}`).then((s: SociedadResp) => {
        resolve(s);
      });
    });
  }

}
