import { Pipe, PipeTransform } from '@angular/core';
import { Cliente } from '../../model/clientes.model';

@Pipe({
  name: 'clientes'
})
export class ClientesPipe implements PipeTransform {

  transform(clientes: Cliente[], text: string): Cliente[] {
    if (!text || text === '' ){
      return clientes;
    }
    text = text.toLowerCase();
    let client: Cliente[];
    client = clientes.filter(c => {
        return c.nombreComercial.toLowerCase().includes(text) ||
               c.nombreFiscal.toLowerCase().includes(text) ||
               c.direccion.toLowerCase().includes(text) ||
               c.localidad.toLowerCase().includes(text) ||
               c.provincia.toLowerCase().includes(text) ||
               c.email.toLowerCase().includes(text) ||
               c.telefono.toString().toLowerCase().includes(text);
    });
    return client;
  }

}
