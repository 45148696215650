import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientesPipe } from './clientes.pipe';
import { ProveedorPipe } from './proveedor.pipe';
import { CampanhaPipe } from './campanha.pipe';
import { TipoGastoPipe } from './tipo-gasto.pipe';
import { TipoIngresoPipe } from './tipo-ingreso.pipe';



@NgModule({
  declarations: [
    ClientesPipe,
    ProveedorPipe,
    CampanhaPipe,
    TipoGastoPipe,
    TipoIngresoPipe
  ],
  exports: [
    ClientesPipe,
    ProveedorPipe,
    CampanhaPipe,
    TipoGastoPipe,
    TipoIngresoPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
