import { Pipe, PipeTransform } from '@angular/core';
import { Proveedor } from '../../model/proveedor.model';

@Pipe({
  name: 'proveedor'
})
export class ProveedorPipe implements PipeTransform {

  transform(proveedores: Proveedor[], text: string): Proveedor[] {
    if (!text || text === '' ){
      return proveedores;
    }
    text = text.toLowerCase();
    let provs: Proveedor[];
    provs = proveedores.filter(data => {
        return data.nombreComercial.toLowerCase().includes(text) ||
               data.nombreFiscal.toLowerCase().includes(text) ||
               data.direccion.toLowerCase().includes(text) ||
               data.localidad.toLowerCase().includes(text) ||
               data.provincia.toLowerCase().includes(text) ||
               data.email.toLowerCase().includes(text) ||
               data.telefono.toString().toLowerCase().includes(text);
    });
    return provs;
  }
}
