import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UiSereviceService {

  constructor(private alertCrtl: AlertController,
    private toastController: ToastController) { }

  async alertError(mensaje: string) {
    const alert = await this.alertCrtl.create({
      header: 'Error',
      message: mensaje,
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    alert.present();
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  presentDateSelect(header: string, message: string, btn?: string) {
    return new Promise<any>(async resolve => {
      const date = new Date;
      const alert = await this.alertCrtl.create({
        header,
        message,
        inputs: [
          {
            name: 'fechaCobro',
            type: 'date',
            min: '2017-03-01',
            max: `${date.getFullYear()-date.getMonth() + 1}-${date.getDate()}`
          }
        ],
        buttons: [
          {
            text: 'Caneclar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(undefined);
            }
          }, {
            text: btn || 'Cobrar',
            handler: (data) => {
              resolve(data);
            }
          }
        ]
      });
      alert.present();

    });
  }

  presentConfirm(message: string) {
    return new Promise<boolean>(async resolve => {
      const alert = await this.alertCrtl.create({
        header: '¿Esta seguro?',
        message,
        buttons: [
          {
            text: 'NO',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(false);
            }
          }, {
            text: 'SI',
            handler: () => {
              resolve(true);
            }
          }
        ]
      });
      alert.present();

    });
  }

}
