import { Component, Input, OnInit } from '@angular/core';
import { Campanha, CampanhaResp } from '../../../model/campanha.model';
import { CampanhaService } from '../../services/campanha.service';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-campanha-select',
  templateUrl: './campanha-select.component.html',
  styleUrls: ['./campanha-select.component.scss'],
})
export class CampanhaSelectComponent implements OnInit {

  campanhas: Campanha[] = [];
  buscar: string;
  @Input() todo: boolean;
  constructor( private campanhaService: CampanhaService,
               private ui: UiSereviceService,
               private modalCtrl: ModalController) { }

  async ngOnInit() {
    this.campanhaService.page = 0;
    let camps: CampanhaResp;
    if( this.todo ){
      camps = await this.campanhaService.getCampanhasSF();
    } else {
      camps = await this.campanhaService.getCampanhasActivas();
    }
    if (!camps.ok){
      this.ui.alertError(camps.error);
      return;
    } 
    this.campanhas = camps.campanhas;

  }

  seleccionado(campanha: Campanha){
    this.modalCtrl.dismiss(campanha);
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }
  async buscarText(event: any){
    this.campanhaService.page = 0;
    let camps;
    if( this.todo ){
      camps = await this.campanhaService.getCampanhasSF(event.detail.value);
    } else {
      camps = await this.campanhaService.getCampanhasActivas(event.detail.value);
    }
    if (!camps.ok) {
      this.ui.alertError(camps.error);
      return;
    }
    this.campanhas = camps.campanhas;
  }

  async cargaMasDatos(event: any){
    let camps;
    if( this.todo ){
      camps = await this.campanhaService.getCampanhasSF();
    } else {
      camps = await this.campanhaService.getCampanhasActivas();
    }
    if (!camps.ok){
      this.ui.alertError(camps.error);
      return;
    }
    this.campanhas.push(...camps.campanhas);
    if (event) {
      event.target.complete();
    }
    
    if (camps.campanhas.length === 0) {
      event.target.disabled = true;
      this.campanhaService.page = 0;
    }
  }

}
