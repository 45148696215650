import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CampanhaService } from '../../services/campanha.service';
import { Campanha } from '../../../model/campanha.model';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-compara-campanha-select',
  templateUrl: './compara-campanha-select.component.html',
  styleUrls: ['./compara-campanha-select.component.scss'],
})
export class ComparaCampanhaSelectComponent implements OnInit {
  campanhas: Campanha[] = [];
  campanhasSelect: Campanha[] = [];
  buscar: string;

  constructor( private modalCtrl: ModalController,
               private ui: UiSereviceService,
               private campanhaService: CampanhaService) { }

  ngOnInit() {
    this.campanhasSelect = [];
    this.cargarCampanhas();
  }

  async cargarCampanhas(){
    this.campanhaService.page = 0;
    const camps = await this.campanhaService.getCampanhasSF(this.buscar);
    if (!camps.ok){
      this.ui.alertError(camps.error);
      return;
    }
    this.campanhas = camps.campanhas;
  }

  seleccionar(campanha: Campanha){
    if (this.campanhasSelect.length >= 4){
      this.ui.alertError('Número máximo de campañas seleccionadas');
      return;
    }
    if (this.campanhasSelect.length > 0 && (this.campanhasSelect[0].cultivo._id !== campanha.cultivo._id)){
      this.ui.alertError('No es posible comparar campañas de diferentes explotaciones');
      return;
    }
    this.campanhas = this.campanhas.filter(f => {
      return f._id != campanha._id;
    });
    this.campanhasSelect.push(campanha);
    this.ui.presentToast('Campnaña añadida para la comparativa!');
  }

  deseleccionar(campanha: Campanha){
    this.campanhas.push(campanha);
    this.campanhasSelect = this.campanhasSelect.filter(f => {
      return f._id != campanha._id;
    });
    this.ui.presentToast('Campaña eliminada de la comparativa');
  };

  comparar(){
    this.modalCtrl.dismiss(this.campanhasSelect);
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }
  buscarText(event: any){
    this.buscar = event.detail.value;
    this.cargarCampanhas();
  }

  async cargaMasDatos(event: any){
    const camps = await this.campanhaService.getCampanhasSF(this.buscar);
    if (!camps.ok){
      this.ui.alertError(camps.error);
      return;
    }
    this.campanhas.push(...camps.campanhas);
    if (event) {
      event.target.complete();
    }
    
    if (camps.campanhas.length === 0) {
      event.target.disabled = true;
      this.campanhaService.page = 0;
    }
  }



}
