import { Component, OnInit } from '@angular/core';
import { Sociedad } from '../../../model/sociedad.model';
import { SociedadesService } from '../../services/sociedades.service';
import { ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-sociedad-select',
  templateUrl: './sociedad-select.component.html',
  styleUrls: ['./sociedad-select.component.scss'],
})
export class SociedadSelectComponent implements OnInit {

  sociedades: Sociedad[] = [];

  constructor( private sociedadService: SociedadesService,
               private modalCtrl: ModalController,
               private ui: UiSereviceService) { }

  async ngOnInit() {
    const socs = await this.sociedadService.getSociedades();
    if (!socs.ok){
      this.ui.alertError(socs.error);
      return;
    }
    this.sociedades = socs.sociedades;
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }
  seleccionado(soc: Sociedad){
    this.modalCtrl.dismiss(soc);
  }

}
