import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { CultivoSelectComponent } from './cultivo-select/cultivo-select.component';
import { FincaSelectComponent } from './finca-select/finca-select.component';
import { CampanhaSelectComponent } from './campanha-select/campanha-select.component';
import { GastoTipoSelecComponent } from './gasto-tipo-selec/gasto-tipo-selec.component';
import { IngresoTipoSelecComponent } from './ingreso-tipo-selec/ingreso-tipo-selec.component';
import { ProveedorSelectComponent } from './proveedor-select/proveedor-select.component';
import { ClienteSelecComponent } from './cliente-selec/cliente-selec.component';
import { FechasComponent } from './fechas/fechas.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SociedadSelectComponent } from './sociedad-select/sociedad-select.component';
import { PipesModule } from '../pipes/pipes.module';
import { ComparaCampanhaSelectComponent } from './compara-campanha-select/compara-campanha-select.component';
import { BancoSelectComponent } from './banco-select/banco-select.component';
import { ImpuestoComponent } from './impuesto/impuesto.component';
import { MultiCampanhaComponent } from './multi-campanha/multi-campanha.component';
import { CampanhaImporteComponent } from './campanha-importe/campanha-importe.component';
import { MetodoPagoComponent } from './metodo-pago/metodo-pago.component';
import { MultiGeneradaComponent } from './multi-generada/multi-generada.component';

const MODULES = [
  CultivoSelectComponent,
  FincaSelectComponent,
  CampanhaSelectComponent,
  GastoTipoSelecComponent,
  IngresoTipoSelecComponent,
  ProveedorSelectComponent,
  ClienteSelecComponent,
  FechasComponent,
  SociedadSelectComponent,
  ComparaCampanhaSelectComponent,
  BancoSelectComponent,
  ImpuestoComponent,
  MultiCampanhaComponent,
  CampanhaImporteComponent,
  MetodoPagoComponent,
  MultiGeneradaComponent
];


@NgModule({
  declarations: MODULES,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    BrowserModule,
    PipesModule
  ]
})
export class SelectModule { }
