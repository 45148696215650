import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { CampanhaResp, Campanha } from '../../model/campanha.model';


@Injectable({
  providedIn: 'root'
})
export class CampanhaService {

  constructor( private dataService: DataService) { }

  page = 0;

  getCampanhasSF(buscar?: string){
    this.page ++;
    return new Promise<CampanhaResp>(resolve => {
      this.dataService.getData(`campanhas?page=${this.page}&buscar=${buscar}`).then((resp: CampanhaResp) => {
        resolve(resp);
      });
    });
  }

  getAll(filtro: any){
    return new Promise<CampanhaResp>(resolve => {
      this.dataService.setData(`campanhas/filtro`, filtro).then((resp: CampanhaResp) => {
        resolve(resp);
      });
    });
  }

  getCampanhas(filtro: any){
    this.page ++;
    return new Promise<CampanhaResp>(resolve => {
      this.dataService.setData(`campanhas/filtro?page=${this.page}`, filtro).then((resp: CampanhaResp) => {
        resolve(resp);
      });
    });
  }

  getTotalesCampanha(campanhaId: string) {
    return new Promise<any>(resolve => {
      this.dataService.getData('campanhas/totales/' + campanhaId).then(resp => {
        resolve(resp);
      });
    });
  }

  getCampanha(id: string){
    return new Promise<CampanhaResp>(resolve => {
      this.dataService.getData(`campanhas/unica/${id}`).then((resp: CampanhaResp) => {
        resolve(resp);
      });
    });
  }
  getCampanhasActivas(buscar?: string){
    this.page ++;
    return new Promise<CampanhaResp>(resolve => {
      this.dataService.getData(`campanhas/activas?page=${this.page}&buscar=${buscar}`).then((resp: CampanhaResp) => {
        resolve(resp);
      });
    });
  }

  setCampanha(campanha: Campanha) {
    return new Promise<CampanhaResp>(resolve => {
      this.dataService.setData('campanhas', campanha).then((resp: CampanhaResp) => {
        resolve(resp);
      });
    });
  }

  updateCampanha(campanha: Campanha){
    return new Promise<CampanhaResp>(resolve => {
      this.dataService.updateData(`campanhas/${campanha._id}`, campanha).then((resp: CampanhaResp) => {
        resolve(resp);
      });
    });
  }

  delCampanha(campanha: Campanha){
    return new Promise<CampanhaResp>(resolve => {
      this.dataService.delData(`campanhas/${campanha._id}`).then((resp: CampanhaResp) => {
        resolve(resp);
      });
    });
  }

  getCampahaMandos(id: any){
    return new Promise<CampanhaResp>(resolve => {
      this.dataService.setData(`campanhas/mandos/${id}`, {}).then(resp => {
        resolve (resp)
      });
    });
  }

}
