import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { BancoResp, Banco } from '../../model/banco.model';

@Injectable({
  providedIn: 'root'
})
export class BancoService {

  constructor( private dataService: DataService) { }

  getBancos(){
    return new Promise<BancoResp>(resolve => {
      this.dataService.getData('bancos').then((resp: BancoResp) => {
        resolve(resp);
      });
    });
  }
  
  setBanco(banco: Banco){
    return new Promise<BancoResp>(resolve => {
      this.dataService.setData('bancos', banco).then((resp: BancoResp) => {
        resolve(resp);
      });
    });
  }

  updateBanco(banco: Banco){
    return new Promise<BancoResp>(resolve => {
      this.dataService.updateData(`bancos/${banco._id}`, banco).then((resp: BancoResp) => {
        resolve(resp);
      });
    });    
  }

  deleteBanco(bancoId: string){
    return new Promise<BancoResp>(resolve => {
      this.dataService.delData(`bancos/${bancoId}`).then((resp: BancoResp) => {
        resolve(resp);
      });
    }); 
  }
}
