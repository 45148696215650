import { registerLocaleData } from '@angular/common';
import { Component } from '@angular/core';
import es from '@angular/common/locales/es';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent{
  public usuario = {};
  public selectedIndex = 0;
  public appPages = [
    { title: 'Inicio', url: 'app/inicio', icon: 'home' },
    { title: 'Empresas', url: 'app/empresas', icon: 'business' },
    { title: 'Clientes', url: 'app/clientes', icon: 'people' },
    { title: 'Proveedores', url: 'app/proveedores', icon: 'briefcase' },
    { title: 'Facturación', url: 'app/facturacion', icon: 'file-tray-stacked' },
    { title: 'Gastos', url: 'app/gastos', icon: 'card' },
    { title: 'Ingresos', url: 'app/ingresos', icon: 'cash' },
    { title: 'Explotaciones', url: 'app/cultivos', icon: 'rose' },
    { title: 'Campañas', url: 'app/campanias', icon: 'calendar-number' },
    { title: 'Inversiones', url: 'app/inversiones', icon: 'diamond' },
    { title: 'Balances', url: 'app/balances', icon: 'analytics' },
 //   { title: 'Personal', url: 'app/personal', icon: 'accessibility' },
    { title: 'Configuración', url: 'app/configuracion', icon: 'options' },
  ];
  constructor() {
    registerLocaleData(es)
  }

}
