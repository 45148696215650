import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-head-modal',
  templateUrl: './head-modal.component.html',
  styleUrls: ['./head-modal.component.scss'],
})
export class HeadModalComponent implements OnInit {

  @Input()titulo = 'NUEVO';
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  cerrar(){
    this.modalCtrl.dismiss();
  }

}
