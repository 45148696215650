import { Component, OnInit } from '@angular/core';
import { Banco } from '../../../model/banco.model';
import { BancoService } from '../../services/banco.service';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-banco-select',
  templateUrl: './banco-select.component.html',
  styleUrls: ['./banco-select.component.scss'],
})
export class BancoSelectComponent implements OnInit {

  bancos: Banco[] = [];

  constructor( private bancoService: BancoService,
               private ui: UiSereviceService,
               private modalCtrl: ModalController) { }

  ngOnInit() {
    this.getBancos();
  }

  async getBancos(){
    const bnc = await this.bancoService.getBancos();
    if (!bnc.ok){
      this.ui.alertError(bnc.error);
      return;
    }
    this.bancos = bnc.bancos;
    this.bancos.unshift({nombre: 'CONTADO'});
  }

  seleccionado(banco: Banco){
    if (banco._id)
    this.modalCtrl.dismiss(banco);
    else this.modalCtrl.dismiss(false);
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }



}
