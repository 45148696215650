import { Component, OnInit } from '@angular/core';
import { Cultivo } from '../../../model/cultivo.model';
import { CultivoService } from '../../services/cultivo.service';
import { ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-cultivo-select',
  templateUrl: './cultivo-select.component.html',
  styleUrls: ['./cultivo-select.component.scss'],
})
export class CultivoSelectComponent implements OnInit {

  cultivos: Cultivo[] = [];
  constructor( private cultivoService: CultivoService,
               private modalCtrl: ModalController,
               private ui: UiSereviceService) { }

  async ngOnInit() {

    const resp = await this.cultivoService.getCultivos();
    if (!resp.ok){
      this.ui.alertError(resp.error);
      return;
    }
    this.cultivos = resp.cultivos;
  }

  seleccionado(cultivo: Cultivo){
    this.modalCtrl.dismiss(cultivo);
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }
}
