import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import globales from '../../environments/globales';
import { UsuarioResp, UsuarioLogin } from '../../model/usuario.model';
import { DataService } from './data.service';


const URL = environment.backendUrl;

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private dataService: DataService) { }

  header = {
    token: ''
  };


  loginUser(email: string, pass: string){
    return new Promise<UsuarioResp>(resolve => {
      this.dataService.setData('usuarios/login', {email, password: pass}).then(async (login: UsuarioLogin) => {
        await this.dataService.saveStorege('token', login.token);
        if (login.ok){
          const user = await this.dataService.getData('usuarios/validar/token');
          globales.usuario = user.usuario;
          resolve(user);  
        }
        resolve(login)
      });
    });
  }

  async verificaLogin(){
    return new Promise<UsuarioResp>(async resolve => {
      const verifica = await  this.dataService.getData('usuarios/validar/token');
      if (!verifica){
        resolve({
          ok: false
        });
      }
      resolve(verifica);
    });
  }

  loguout(){
    this.header.token = null;
    return new Promise(resolve => {
        resolve(this.dataService.delStorage('token'));
    });

  }
}
