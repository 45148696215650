import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';


@Component({
  selector: 'app-fechas',
  templateUrl: './fechas.component.html',
  styleUrls: ['./fechas.component.scss'],
})
export class FechasComponent implements OnInit {

  intervalo: boolean;
  datei: Date;
  dateo: Date;
  constructor(private modalCtrl: ModalController,
              private ui: UiSereviceService) { }

  ngOnInit() {}

  buscar(){
    this.modalCtrl.dismiss({fechaini: this.datei, fechaFin: this.dateo});
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }
}
