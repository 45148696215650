import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Campanha } from '../../../model/campanha.model';
import { GastoTipoSelecComponent } from '../gasto-tipo-selec/gasto-tipo-selec.component';
import { GastoTipo } from '../../../model/gastos.model';

@Component({
  selector: 'app-campanha-importe',
  templateUrl: './campanha-importe.component.html',
  styleUrls: ['./campanha-importe.component.scss'],
})
export class CampanhaImporteComponent implements OnInit {

  @Input()campanha: Campanha;
  @Input()iva: number;
  importe: number;
  tipoGasto: GastoTipo = {};
  constructor( private modalCtrl: ModalController) { }

  ngOnInit() {
    this.iva = Number(this.iva.toFixed(0));
  }

  async tipoSelect(){
    const modal = await this.modalCtrl.create({
      component: GastoTipoSelecComponent,
      cssClass: 'proveedorSelect-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      this.tipoGasto = data;
    }
  }

  add(){
    const total = this.importe * ((this.iva / 100) + 1);
    this.modalCtrl.dismiss({campanha: this.campanha, importe: this.importe.toFixed(2), tipo: this.tipoGasto, total: total.toFixed(2)});
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }

}
