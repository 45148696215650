import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Gasto } from '../../../model/gastos.model';
import { CampanhaSelectComponent } from '../campanha-select/campanha-select.component';
import { CampanhaImporteComponent } from '../campanha-importe/campanha-importe.component';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { GastoIngresoService } from '../../services/gasto-ingreso.service';


@Component({
  selector: 'app-multi-campanha',
  templateUrl: './multi-campanha.component.html',
  styleUrls: ['./multi-campanha.component.scss'],
})
export class MultiCampanhaComponent implements OnInit {

  @Input()gasto: Gasto;
  campanhas: any[] = [];
  base: number = 0;
  total: number = 0;
  constructor( private modalCtrl: ModalController,
               private ui: UiSereviceService,
               private gastoService: GastoIngresoService) { }

  ngOnInit() {}

  cerrar(){
    this.modalCtrl.dismiss();
  }

  async addCampanha(){
    const modal = await this.modalCtrl.create({
      component: CampanhaSelectComponent,
      cssClass: 'campanhaSelect-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    const campanha = data
    if (data){
      const modal = await this.modalCtrl.create({
        component: CampanhaImporteComponent,
        cssClass: 'lineaMultiGasto-modal',
        componentProps: {
          campanha,
          iva: ((this.gasto.total / this.gasto.importe) - 1) *100
        }
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data){

        this.campanhas.push(data);
        this.base += Number(data.importe);
        this.base = Number(this.base.toFixed(2));
        this.total += Number(data.total);
        this.total = Number(this.total.toFixed(2));
        if (this.base > this.gasto.importe){
          this.ui.alertError('Se ha sobrepasado el limite de la factura, elimine un registro para continuar');
        }
      }
    }
  }

  verificarTotal(): boolean {

    if (this.base > this.gasto.importe || this.base < this.gasto.importe){
      return true;
    } else {
      if (this.total > this.gasto.total || this.total < this.gasto.total){
        return true;
      } else {
        return false
      }
    }
  }
  del(ln: any){
    this.base -= ln.importe;
    this.total -= ln.total;
    this.campanhas = this.campanhas.filter(f => {
      return f != ln;
    });
  }

  async enviar(){
    let n: number = 0;
    let gastos: any[] = [];
    this.campanhas.forEach(c => {
      n++;
      let banco;
      let factura;
      if (this.gasto.banco){
          banco = this.gasto.banco._id;
      }
      if (this.gasto.factura){
        factura = this.gasto.factura + '-' + n;
      }
      const gasto: Gasto = {
        descripcion: this.gasto.descripcion,
        tipo: c.tipo._id,
        banco,
        proveedor: this.gasto.proveedor._id,
        factura,
        fechaAlta: this.gasto.fechaAlta,
        importe: c.importe, // Base
        total: c.total,
        campanha: c.campanha._id,
        documento: this.gasto.documento
      }
      gastos.push(gasto);
    });

    const gst = await this.gastoService.setgasto(gastos);
    if (!gst.ok){
      this.ui.alertError('Error al generar el gasto de multicampaña campaña');
    }
    this.modalCtrl.dismiss(true);
  }
}
