import { Component, OnInit } from '@angular/core';
import { ProveedorService } from '../../services/proveedor.service';
import { FormaPago } from '../../../model/formaPago.model';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-metodo-pago',
  templateUrl: './metodo-pago.component.html',
  styleUrls: ['./metodo-pago.component.scss'],
})
export class MetodoPagoComponent implements OnInit {

  metodosPago: FormaPago[] = [];

  constructor( private proveedorService: ProveedorService, private ui: UiSereviceService, private modalCtrl: ModalController) { }

  async ngOnInit() {
    const resp = await this.proveedorService.getFormasPago();
    if (!resp.ok){
      return this.ui.alertError(resp.error);
    }
    this.metodosPago = resp.formasPago;
  }

  cerrar(){
    this.modalCtrl.dismiss(null);
  }
  seleccionado(mp: FormaPago){
   // console.log(mp.nombre);
    this.modalCtrl.dismiss(mp);
  }

}
