import { ClienteResp } from './../../model/clientes.model';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Cliente } from '../../model/clientes.model';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {
  
  page: number = 0;
  constructor( private dataService: DataService) { }


  getClientes(buscar?: string) {
    this.page ++;
    return new Promise<ClienteResp>(resolve => {
        this.dataService.getData(`clientes?page=${this.page}&buscar=${buscar}`).then((resp: ClienteResp) => {
          resolve(resp);
        });
    });
  }

  getClienteId(clienteId: string) {
    return new Promise<ClienteResp>(resolve => {
        this.dataService.getData(`clientes/${clienteId}`).then((resp: ClienteResp) => {
          resolve(resp);
        });
    });
  }

  setCliente(cliente: Cliente) {
    return new Promise<ClienteResp>(resolve => {
      this.dataService.setData('clientes', cliente).then((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  updateClient(cliente: Cliente){
    return new Promise<ClienteResp>(resolve => {
        this.dataService.updateData(`clientes/${cliente._id}`, cliente).then((resp: ClienteResp) => {
          resolve(resp);
        });
    });
  }

  delClient(cliente: Cliente){
    return new Promise<ClienteResp>(resolve => {
      this.dataService.delData(`clientes/${cliente._id}`).then((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }
}
