import { Component, OnInit } from '@angular/core';
import { Cliente } from '../../../model/clientes.model';
import { ClientesService } from '../../services/clientes.service';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-cliente-selec',
  templateUrl: './cliente-selec.component.html',
  styleUrls: ['./cliente-selec.component.scss'],
})
export class ClienteSelecComponent implements OnInit {

  clientes: Cliente[] = [];
  buscar: string;
  constructor( private clienteService: ClientesService,
               private ui: UiSereviceService,
               private modalCtrl: ModalController) { }

  async ngOnInit() {
    this.clienteService.page = 0;
    const cli = await this.clienteService.getClientes();
    if (!cli.ok){
      this.ui.alertError(cli.error);
      return;
    } 
    this.clientes = cli.clientes;
  }
  cerrar(){
    this.modalCtrl.dismiss();
  }

  seleccionado(cli: Cliente){
    this.modalCtrl.dismiss(cli);
  }

  async buscarCliente(event: any){
    this.clienteService.page = 0;
    const cli = await this.clienteService.getClientes(event.detail.value);
    if (!cli.ok){
      this.ui.alertError(cli.error);
      return;
    }
    this.clientes = cli.clientes;
  }

  async cargaMasDatos(event: any){
    const cli = await this.clienteService.getClientes();
    if (!cli.ok){
      this.ui.alertError(cli.error);
      return;
    }
    this.clientes.push(...cli.clientes);
    if (event) {
      event.target.complete();
    }
    
    if (cli.clientes.length === 0) {
      event.target.disabled = true;
      this.clienteService.page = 0;
    }
  }



}
