import { Component, OnInit } from '@angular/core';
import { Impuesto } from '../../../model/impuesto.model';
import { ImpuestoService } from '../../services/impuesto.service';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-impuesto',
  templateUrl: './impuesto.component.html',
  styleUrls: ['./impuesto.component.scss'],
})
export class ImpuestoComponent implements OnInit {

  impuestos: Impuesto[] = [];
  constructor( private impuestosServices: ImpuestoService,
               private ui: UiSereviceService,
               private modalCtrl: ModalController) { }

  ngOnInit() {
    this.cargarImpuestos();
  }

  async cargarImpuestos(){
    const imps = await this.impuestosServices.getImpuestos();
    if(!imps.ok){
      this.ui.alertError(imps.error);
      return;
    }
    this.impuestos = imps.impuestos;
  }

  seleccionado(imp: Impuesto){
    this.modalCtrl.dismiss(imp);
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }

}
