import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ProveedorResp, Proveedor } from '../../model/proveedor.model';
import { FormaPagoResp, FormaPago } from '../../model/formaPago.model';

@Injectable({
  providedIn: 'root'
})
export class ProveedorService {

  page: number = 0;
  constructor( private dataService: DataService) { }

  getProveedor(filter: any = {}, buscar?: string ) {
    this.page ++;
    return new Promise<ProveedorResp>(resolve => {
        this.dataService.setData(`proveedores/get?page=${this.page}&buscar=${buscar}`, filter).then((resp: ProveedorResp) => {
          resolve(resp);
        });
    });
  }

  setProveedor(proveedor: Proveedor) {
    return new Promise<ProveedorResp>(resolve => {
      this.dataService.setData('proveedores', proveedor).then((resp: ProveedorResp) => {
        resolve(resp);
      });
    });
  }

  updateProveedor(proveedor: Proveedor){
    return new Promise<ProveedorResp>(resolve => {
        this.dataService.updateData(`proveedores/${proveedor._id}`, proveedor).then((resp: ProveedorResp) => {
          resolve(resp);
        });
    });
  }

  delProveedor(proveedor: Proveedor){
    return new Promise<ProveedorResp>(resolve => {
      this.dataService.delData(`proveedores/${proveedor._id}`).then((resp: ProveedorResp) => {
        resolve(resp);
      });
    });
  }

  getFormasPago(){
    return new Promise<FormaPagoResp>( resolve => {
      this.dataService.getData('formas-pago').then((resp: FormaPagoResp) => {
        resolve(resp);
      });
    });
  }

  getFormasPagoId(id: string){
    return new Promise<FormaPagoResp>( resolve => {
      this.dataService.getData(`formas-pago/${id}`).then((resp: FormaPagoResp) => {
        resolve(resp);
      });
    });
  }

  setFormaPago(formaPago: FormaPago){
    return new Promise<FormaPagoResp>(resolve => {
      this.dataService.setData('formas-pago', formaPago).then((resp: FormaPagoResp) => {
        resolve(resp);
      });
    });
  }

  delFormaPago(formaPagpId: string){
    return new Promise<FormaPagoResp>(resolve => {
      this.dataService.delData(`formas-pago/${formaPagpId}`).then((resp: FormaPagoResp) => {
        resolve(resp);
      });
    })
  }
}
