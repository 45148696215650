import { Component, OnInit } from '@angular/core';
import { Finca } from '../../../model/finca.model';
import { FincaService } from '../../services/finca.service';
import { ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-finca-select',
  templateUrl: './finca-select.component.html',
  styleUrls: ['./finca-select.component.scss'],
})
export class FincaSelectComponent implements OnInit {

  fincas: Finca[] = [];
  constructor( private fincaService: FincaService,
               private modalCtrl: ModalController,
               private ui: UiSereviceService) { }

  async ngOnInit() {
      const resp = await this.fincaService.getFincas();
      if (!resp.ok){
        this.ui.alertError(resp.error);
        return;
      }
      this.fincas = resp.fincas;
  }

  seleccionado(finca: Finca){
    this.modalCtrl.dismiss(finca);
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }

}
