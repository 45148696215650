import { Component, OnInit } from '@angular/core';
import { GastoIngresoService } from '../../services/gasto-ingreso.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-multi-generada',
  templateUrl: './multi-generada.component.html',
  styleUrls: ['./multi-generada.component.scss'],
})
export class MultiGeneradaComponent implements OnInit {

  constructor(private gastoService: GastoIngresoService, private modalCtrl: ModalController) { }

  multi: any[] = [];

  async ngOnInit() {
    const resp: any = await this.gastoService.getgastosMulti();
    this.multi = resp.multi;
  }

  async seleccionado(m: any){
    this.gastoService.page = 0;
    const resp = await this.gastoService.getgastos({nfactura: m.nfactura});
    this.modalCtrl.dismiss(resp.gastos[0]);
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }
  async buscarText(event: any){
    const text: string = event.detail.value;
    if (text.length > 0){
      this.multi = this.multi.filter(f => {
        if (String(f.nfactura).includes(text)){
          return f
        }
      });
    } else {
      this.ngOnInit();
    }
  }

  async cargaMasDatos(event: any){
  }

}
