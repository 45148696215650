import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { CultivoResp, Cultivo } from '../../model/cultivo.model';

@Injectable({
  providedIn: 'root'
})
export class CultivoService {

  constructor(private dataService: DataService) { }

  getCultivos(search?: string){
    return new Promise<CultivoResp>(resolve => {
      this.dataService.getData('cultivos?search=' + search).then((resp: CultivoResp) => {
        resolve(resp);
      });
    });
  }

  setCultivo(cultivo: Cultivo){
    return new Promise<CultivoResp>(resolve => {
      this.dataService.setData('cultivos', cultivo).then((resp: CultivoResp) => {
        resolve(resp);
      });
    });
  }

  updateCultivo(cultivo: Cultivo){
    return new Promise<CultivoResp>(resolve => {
      this.dataService.updateData(`cultivos/${cultivo._id}`, cultivo).then((resp: CultivoResp) => {
        resolve(resp);
      });
    });
  }

  delCultivo(cultivo: Cultivo){
    return new Promise<CultivoResp>(resolve => {
      this.dataService.delData(`cultivos/${cultivo._id}`).then((resp: CultivoResp) => {
        resolve(resp);
      });
    });
  }
}
