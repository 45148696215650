import { Component, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import globales from '../../../environments/globales';
import { Usuario } from '../../../model/usuario.model';
import { Router } from '@angular/router';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { MenuController } from '@ionic/angular';


@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.scss'],
})


export class HeadComponent {
  
  @Input()titulo: string;
  
  
  usuario: Usuario = globales.usuario || {email: ''};
  constructor( private dataService: DataService,
               private ui: UiSereviceService,
               private menuCtrl: MenuController,
               private router: Router) { }



  async salir(){
    const ok = await this.dataService.delStorage('token');
    if(!ok){
      this.ui.alertError('Error al eliminar los datos de login');
      this.menuCtrl.enable(false);
      this.router.navigateByUrl('/', { replaceUrl:true });
     // this.navCtrl.navigateRoot('/login');
     // window.location.reload();
    } else {
      this.menuCtrl.enable(false);
      this.router.navigateByUrl('/', { replaceUrl:true });
    }
  }

}
