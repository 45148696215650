import { Component, OnInit } from '@angular/core';
import { ProveedorService } from '../../services/proveedor.service';
import { ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { Proveedor } from '../../../model/proveedor.model';

@Component({
  selector: 'app-proveedor-select',
  templateUrl: './proveedor-select.component.html',
  styleUrls: ['./proveedor-select.component.scss'],
})
export class ProveedorSelectComponent implements OnInit {

  proveedores: Proveedor[] = [];
  buscar: string;
  constructor( private proveedorService: ProveedorService,
               private modalCtrl: ModalController,
               private ui: UiSereviceService) { }

  async ngOnInit() {
    this.proveedorService.page = 0;
    const prov = await this.proveedorService.getProveedor();
    if (!prov.ok){
      this.ui.alertError(prov.error);
      return;
    }
    this.proveedores = prov.proveedores;
  }

  seleccionado(proveedor: Proveedor){
    this.modalCtrl.dismiss(proveedor);
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }

  async buscarText(event: any){
    this.proveedorService.page  = 0;
    const proveedores = await this.proveedorService.getProveedor({}, event.detail.value);
    if (!proveedores.ok) {
      this.ui.alertError(proveedores.error);
      return;
    }
    this.proveedores = proveedores.proveedores;
  }

  async cargaMasDatos(event: any){
    const proveedores = await this.proveedorService.getProveedor();
    if (!proveedores.ok){
      this.ui.alertError(proveedores.error);
      return;
    }
    this.proveedores.push(...proveedores.proveedores);
    if (event) {
      event.target.complete();
    }

    if (proveedores.proveedores.length === 0) {
      event.target.disabled = true;
      this.proveedorService.page = 0;
    }
  }

}
