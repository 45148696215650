import { Component, OnInit } from '@angular/core';
import { GastoIngresoService } from '../../services/gasto-ingreso.service';
import { ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { GastoTipo } from '../../../model/gastos.model';

@Component({
  selector: 'app-gasto-tipo-selec',
  templateUrl: './gasto-tipo-selec.component.html',
  styleUrls: ['./gasto-tipo-selec.component.scss'],
})
export class GastoTipoSelecComponent implements OnInit {

  gastosTipo: GastoTipo[] = [];
  buscar: string;
  constructor( private gastoService: GastoIngresoService,
               private modalCtrl: ModalController,
               private ui: UiSereviceService) { }

  async ngOnInit() {
    const gt = await this.gastoService.getGastosTipo();
    if (!gt.ok){
      this.ui.alertError(gt.error);
      return;
    }
    this.gastosTipo = gt.gastosTipo;
  }

  buscarText(event: any){
    this.buscar = event.detail.value;
  }
  seleccionado(gastoTipo: GastoTipo){
    this.modalCtrl.dismiss(gastoTipo);
  }
  cerrar(){
    this.modalCtrl.dismiss();
  }
}
