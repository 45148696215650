import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'inicio',
        loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
      },
      {
        path: 'empresas',
        loadChildren: () => import('./pages/empresas/empresas.module').then( m => m.EmpresasPageModule)
      },
      {
        path: 'clientes',
        loadChildren: () => import('./pages/clientes/clientes.module').then( m => m.ClientesPageModule)
      },
      {
        path: 'proveedores',
        loadChildren: () => import('./pages/proveedores/proveedores.module').then( m => m.ProveedoresPageModule)
      },
      {
        path: 'facturacion',
        loadChildren: () => import('./pages/facturacion/facturacion.module').then( m => m.FacturacionPageModule)
      },
      {
        path: 'fincas',
        loadChildren: () => import('./pages/fincas/fincas.module').then( m => m.FincasPageModule)
      },
      {
        path: 'cultivos',
        loadChildren: () => import('./pages/cultivos/cultivos.module').then( m => m.CultivosPageModule)
      },
      {
        path: 'campanias',
        loadChildren: () => import('./pages/campanias/campanias.module').then( m => m.CampaniasPageModule)
      },
      {
        path: 'balances',
        loadChildren: () => import('./pages/balances/balances.module').then( m => m.BalancesPageModule)
      },
      {
        path: 'personal',
        loadChildren: () => import('./pages/personal/personal.module').then( m => m.PersonalPageModule)
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./pages/usuarios/usuarios.module').then( m => m.UsuariosPageModule)
      },
      {
        path: 'configuracion',
        loadChildren: () => import('./pages/configuracion/configuracion.module').then( m => m.ConfiguracionPageModule)
      },
      {
        path: 'sociedad-form',
        loadChildren: () => import('./formularios/sociedad-form/sociedad-form.module').then( m => m.SociedadFormPageModule)
      },
      {
        path: 'gastos',
        loadChildren: () => import('./pages/gastos/gastos.module').then( m => m.GastosPageModule)
      },
      {
        path: 'ingresos',
        loadChildren: () => import('./pages/ingresos/ingresos.module').then( m => m.IngresosPageModule)
      },
      {
        path: 'inversiones',
        loadChildren: () => import('./pages/inversiones/inversiones.module').then( m => m.InversionesPageModule)
      },
      {
        path: 'cliente-form',
        loadChildren: () => import('./formularios/cliente-form/cliente-form.module').then( m => m.ClienteFormPageModule)
      },
      {
        path: 'proveedor-form',
        loadChildren: () => import('./formularios/proveedor-form/proveedor-form.module').then( m => m.ProveedorFormPageModule)
      },
      {
        path: 'formapago-form',
        loadChildren: () => import('./formularios/formapago-form/formapago-form.module').then( m => m.FormapagoFormPageModule)
      },
      {
        path: 'finca-form',
        loadChildren: () => import('./formularios/finca-form/finca-form.module').then( m => m.FincaFormPageModule)
      },
      {
        path: 'explotacion-form',
        loadChildren: () => import('./formularios/explotacion-form/explotacion-form.module').then( m => m.ExplotacionFormPageModule)
      },
      {
        path: 'campanha-form',
        loadChildren: () => import('./formularios/campanha-form/campanha-form.module').then( m => m.CampanhaFormPageModule)
      },
      {
        path: 'gasto-ingreso',
        loadChildren: () => import('./formularios/gasto-ingreso/gasto-ingreso.module').then( m => m.GastoIngresoPageModule)
      },
      {
        path: 'gasto-form',
        loadChildren: () => import('./formularios/gasto-form/gasto-form.module').then( m => m.GastoFormPageModule)
      },
      {
        path: 'ingreso-form',
        loadChildren: () => import('./formularios/ingreso-form/ingreso-form.module').then( m => m.IngresoFormPageModule)
      },
      {
        path: 'factura-form',
        loadChildren: () => import('./formularios/factura-form/factura-form.module').then( m => m.FacturaFormPageModule)
      },
      {
        path: 'banco-form',
        loadChildren: () => import('./formularios/banco-form/banco-form.module').then( m => m.BancoFormPageModule)
      },
      {
        path: 'linea-factura',
        loadChildren: () => import('./formularios/linea-factura/linea-factura.module').then( m => m.LineaFacturaPageModule)
      },
      {
        path: 'comparativa',
        loadChildren: () => import('./pages/comparativa/comparativa.module').then( m => m.ComparativaPageModule)
      },
      {
        path: 'usuario-form',
        loadChildren: () => import('./formularios/usuario-form/usuario-form.module').then( m => m.UsuarioFormPageModule)
      },
      {
        path: 'impuesto-form',
        loadChildren: () => import('./formularios/impuesto-form/impuesto-form.module').then( m => m.ImpuestoFormPageModule)
      },
      {
        path: 'inversion-form',
        loadChildren: () => import('./formularios/inversion-form/inversion-form.module').then( m => m.InversionFormPageModule)
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
