import { Pipe, PipeTransform } from '@angular/core';
import { Campanha } from '../../model/campanha.model';

@Pipe({
  name: 'campanha'
})
export class CampanhaPipe implements PipeTransform {

  transform(campanhas: Campanha[], text: string): Campanha[] {
    if (!text || text === '' ){
      return campanhas;
    }
    text = text.toLowerCase();
    let camps: Campanha[];
    camps = campanhas.filter(c => {
        return c.nombre.toLocaleLowerCase().includes(text);
    });
    return camps;
  }
}
