import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadComponent } from './head/head.component';
import { FooterComponent } from './footer/footer.component';
import { IonicModule } from '@ionic/angular';
import { HeadModalComponent } from './head-modal/head-modal.component';
import { FooterModalComponent } from './footer-modal/footer-modal.component';
import { LoadComponent } from './load/load.component';

const MODULE = [
  HeadComponent,
  FooterComponent,
  HeadModalComponent,
  FooterModalComponent,
  LoadComponent,
]

@NgModule({
  declarations: [
    HeadComponent,
    FooterComponent,
    HeadModalComponent,
    FooterModalComponent,
    LoadComponent,
  ],
  exports: MODULE,
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class SharedModule { }
