import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ImpuestoResp, Impuesto } from '../../model/impuesto.model';

@Injectable({
  providedIn: 'root'
})
export class ImpuestoService {

  constructor(private dataService: DataService) { }

  getImpuestos() {
    return new Promise<ImpuestoResp>(resolve => {
      this.dataService.getData('impuesto').then((resp: ImpuestoResp) => {
        resolve(resp);
      });
    });
  }

  setImpuesto(impuesto: Impuesto) {
    return new Promise<ImpuestoResp>(resolve => {
      this.dataService.setData('impuesto', impuesto).then((resp: ImpuestoResp) => {
        resolve(resp);
      });
    });
  }

  updateImpuesto(impuesto: Impuesto){
    return new Promise<ImpuestoResp>(resolve => {
      this.dataService.updateData(`impuesto/${impuesto._id}`, impuesto).then((resp: ImpuestoResp) => {
        resolve(resp);
      });
    });
  }

  deleteImpuesto(impuestoId: string){
    return new Promise<ImpuestoResp>(resolve => {
      this.dataService.delData(`impuesto/${impuestoId}`).then((resp: ImpuestoResp) => {
        resolve(resp);
      });
    });
  }
}
