import { Pipe, PipeTransform } from '@angular/core';
import { GastoTipo } from '../../model/gastos.model';

@Pipe({
  name: 'tipoGasto'
})
export class TipoGastoPipe implements PipeTransform {

  transform(tipos: GastoTipo[], text: string): GastoTipo[] {
    if (!text || text === '' ){
      return tipos;
    }
    text = text.toLowerCase();
    let gts: GastoTipo[];
    gts = tipos.filter(data => {
      return String(data.cod).toLocaleLowerCase().includes(text) ||
             data.nombre.toLocaleLowerCase().includes(text);
    });
    return gts;
  }

}
