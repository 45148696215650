import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment.prod';
import { toSegments } from '@ionic/angular/directives/navigation/stack-utils';

const URL = environment.backendUrl;

@Injectable({
  providedIn: 'root'
})

// Servicio CRUD gestión de datos genéricos
export class DataService {

  header = {
    token: ''
  };

  constructor(private storage: Storage,
              private http: HttpClient

              ) { }

  // Carga de token y añadido a header
  private async cargarHeader(){
    const token = await this.storage.get('token');
    if (!token){
      this.header.token = '';
      return;
    }
    this.header.token = token;
  }


  // Solicita datos al Backend
  async getData(consulta: string){
   await this.cargarHeader();
   return new Promise<any>(resolve => {
      this.http.get(`${URL}/${consulta}`, {headers: this.header}).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  // Envia datos al Backend (Crea un nuevo registro)
  async setData(consulta: string, datos: any){
    await this.cargarHeader();
    return new Promise<any>(resolve => {
      this.http.post(`${URL}/${consulta}`, datos, {headers: this.header}).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  // Actualiza datos en el Backend
  async updateData(consulta: string, datos: any){
    await this.cargarHeader();
    return new Promise<any>(resolve => {
      this.http.put(`${URL}/${consulta}`, datos, {headers: this.header}).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  // Elimina un registro en el Backend
  async delData(consulta: string){
    await this.cargarHeader();
    return new Promise<any>(resolve => {
      this.http.delete(`${URL}/${consulta}`, {headers: this.header}).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  // Upload documento a un directorio /Uploads
  async uploadDocumento(consulta: string, file: File){
    await this.cargarHeader();
    const formData: FormData = new FormData();
    formData.append('doc', file);
    return new Promise((resolve) => {
       this.http.post(`${URL}/${consulta}`, formData, { headers: this.header }).subscribe((resp: any) => {
        resolve(resp);
      });
    });
  }

  // Download documento a un directorio /Uploads
  async downloadDocumento(consulta: string){
    await this.cargarHeader();    
    return new Promise((resolve) => {
      this.http.get(`${URL}/${consulta}`, { headers: this.header, responseType: 'blob' }).subscribe((resp: any) => {
        resolve(resp);
      });
    });
  }

  // Elimina documento
  async delDocumento(consulta: string){
    await this.cargarHeader();
    return new Promise(resolve => {
      this.http.delete(`${URL}/${consulta}`, {headers: this.header}).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  // Almacena en storage
  async saveStorege(tabla: string, dato: any){
    const save = await this.storage.set(tabla, dato);
    if (!save){
      return {
        ok: false,
        error: 'Error en sistema de almacenamieto interno.'
      };
    }
    return {
      ok: true,
    };
  }

  // Lee de storage
  async readStorage(tabla: string){
    const read = await this.storage.get(tabla);
    if (!read){
      return {
        ok: false,
        error: 'No se ha leido desde el amacenamiento interno.'
      };
    }
    return {
      ok: true,
      read
    };
  }

  // Alimina del storage una tabla
  delStorage(tabla: string){
    return new Promise<boolean>(resolve => {
      this.storage.remove(tabla).then(() => {
        resolve(true);
      }).catch(e => {
        resolve(false);
      });
     });
    }
}
