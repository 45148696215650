import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { FincaResp, Finca } from '../../model/finca.model';

@Injectable({
  providedIn: 'root'
})
export class FincaService {

  constructor( private dataService: DataService) { }

  getFincas(){
    return new Promise<FincaResp>(resolve => {
      this.dataService.getData('fincas').then((resp: FincaResp) => {
        resolve(resp);
      });
    });
  }
  getFincaId(fincaId: string){
    return new Promise<FincaResp>(resolve => {
      this.dataService.getData(`fincas/${fincaId}`).then((resp: FincaResp) => {
        resolve(resp);
      });
    });
  }

  setFinca(finca: Finca){
    return new Promise<FincaResp>(resolve => {
      this.dataService.setData('fincas', finca).then((resp: FincaResp) => {
        resolve(resp);
      });
    });
  }

  updateFinca(finca: Finca){
    return  new Promise<FincaResp>(resolve => {
      this.dataService.updateData(`fincas/${finca._id}`, finca).then((resp: FincaResp) => {
        resolve(resp);
      });
    });
  }

  delFinca(finca: Finca){
    return new Promise<FincaResp>(resolve => {
      this.dataService.delData(`fincas/${finca._id}`).then((resp: FincaResp) => {
        resolve(resp);
      });
    });
  }

}
