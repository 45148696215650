import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { GastoTipo, GastoTipoResp, GastoResp, Gasto } from '../../model/gastos.model';
import { IngresoTipoResp, IngresoTipo } from '../../model/ingresosTipo.model';
import { IngresoResp, Ingreso } from '../../model/ingreso.model';


@Injectable({
  providedIn: 'root'
})
export class GastoIngresoService {

  constructor( private dataService: DataService) { }

  page = 0;

  //*** SERVICIOS GASTOS ****//

  getgastos(filtro: any){
    this.page++;
    return new Promise<GastoResp>(resolve => {
      this.dataService.setData(`gasto/filtro?page=${this.page}`, filtro).then((resp: GastoResp) => {
        resolve(resp);
      });
    });
  }
  getgastosMulti(){
    return new Promise<GastoResp>(resolve => {
      this.dataService.getData(`gasto/multi`).then((resp: GastoResp) => {
        resolve(resp);
      });
    });
  }

  getAllGastos(filtro: any){
    return new Promise<GastoResp>(resolve => {
      this.dataService.setData(`gasto/filtro`, filtro).then((resp: IngresoResp) => {
        resolve(resp);
      });
    });
  }

  setgasto(gastos: Gasto[] = [], multi?: boolean){
    return new Promise<GastoResp>(resolve => {
      this.dataService.setData('gasto', {gastos, multi}).then((resp: GastoResp) => {
        resolve(resp);
      });
    });
  }

  updateGasto(gasto: Gasto){
    return new Promise<GastoResp>(resolve => {
      this.dataService.updateData(`gasto/${gasto._id}`, gasto).then((resp: GastoResp) => {
        resolve(resp);
      });
    });
  }

  delelteGasto(gasto: Gasto){
    return new Promise<GastoResp>(resolve => {
      this.dataService.delData(`gasto/${gasto._id}`).then((resp: GastoResp) => {
        resolve(resp);
      });
    });
  }

  //*** SERVICIOS INGRESOS *****//

  getIngresos(filtro: any){
    this.page ++;
    return new Promise<IngresoResp>(resolve => {
      this.dataService.setData(`ingreso/filtro?page=${this.page}`, filtro).then((resp: IngresoResp) => {
        resolve(resp);
      });
    });
  }

  getAllIngreso(filtro: any){
    return new Promise<IngresoResp>(resolve => {
      this.dataService.setData(`ingreso/filtro`, filtro).then((resp: IngresoResp) => {
        resolve(resp);
      });
    });
  }

  setIngreso(ingreso: Ingreso){
    return new Promise<IngresoResp>(resolve => {
      this.dataService.setData('ingreso', ingreso).then((resp: IngresoResp) => {
        resolve(resp);
      })
    });
  }

  updateIngreso(ingreso: Ingreso){
    return new Promise<IngresoResp>(resolve => {
      this.dataService.updateData(`ingreso/${ingreso._id}`, ingreso).then((resp: IngresoResp) => {
        resolve(resp);
      });
    });
  }

  deleteIngreso(ingreId: string){
    return new Promise<IngresoResp>(resolve => {
      this.dataService.delData(`ingreso/${ingreId}`).then(async (resp: IngresoResp) => {
        resolve(resp);
      });
    });
  };


  //*** SERVICIOS GASTOS TIPO ****//

  getGastosTipo(){
    return new Promise<GastoTipoResp>(resolve => {
      this.dataService.getData('gasto/tipo').then((resp: GastoTipoResp) => {
        resolve(resp);
      });
    });
  }

  getGastosTipoId(idGastoTipo: string){
    return new Promise<GastoTipoResp>(resolve => {
      this.dataService.getData(`gasto/tipo${idGastoTipo}`).then((resp: GastoTipoResp) => {
        resolve(resp);
      });
    });
  }

  setGastoTipo(gastoTipo: GastoTipo){
    return new Promise<GastoTipoResp>(resolve => {
      this.dataService.setData('gasto/tipo', gastoTipo).then((resp: GastoTipoResp) => {
        resolve(resp);
      });
    })
  }

  updateGastoTipo(gastoTipo: GastoTipo){
    return new Promise<GastoTipoResp>(resolve => {
      this.dataService.updateData(`gasto/tipo/${gastoTipo._id}`, gastoTipo).then((resp: GastoTipoResp) => {
        resolve(resp);
      });
    });
  }

  deleteGastoTipo(idGastoTipo: string, idDestino: string){
    return new Promise<GastoTipoResp>(resolve => {
      this.dataService.delData(`gasto/tipo/${idGastoTipo}/${idDestino}`).then((resp: GastoTipoResp) => {
        resolve(resp);
      });
    });
  }


  //*** SERVICIOS INGRESOS TIPO ****//

  getIngresoTipo(){
    return new Promise<IngresoTipoResp>(resolve => {
      this.dataService.getData('ingreso/tipo').then((resp: IngresoTipoResp) => {
        resolve(resp);
      });
    });
  }

  getIngresoTipoId(idGastoTipo: string){
    return new Promise<IngresoTipoResp>(resolve => {
      this.dataService.getData(`ingreso/tipo${idGastoTipo}`).then((resp: IngresoTipoResp) => {
        resolve(resp);
      });
    });
  }

  setIngresoTipo(gastoTipo: IngresoTipo){
    return new Promise<IngresoTipoResp>(resolve => {
      this.dataService.setData('ingreso/tipo', gastoTipo).then((resp: IngresoTipoResp) => {
        resolve(resp);
      });
    })
  }

  updateIngresoTipo(gastoTipo: IngresoTipo){
    return new Promise<IngresoTipoResp>(resolve => {
      this.dataService.updateData(`ingreso/tipo/${gastoTipo._id}`, gastoTipo).then((resp: IngresoTipoResp) => {
        resolve(resp);
      });
    });
  }

  deleteIngresoTipo(idGastoTipo: string, idDestino: string){
    return new Promise<IngresoTipoResp>(resolve => {
      this.dataService.delData(`ingreso/tipo/${idGastoTipo}/${idDestino}`).then((resp: IngresoTipoResp) => {
        resolve(resp);
      });
    });
  }

}
